import React, { useRef } from 'react';
import {
    InstantSearch,
    RefinementList,
    SortBy,
    Pagination,
    ClearRefinements,
    Hits,
    HitsPerPage,
    Panel,
    Configure,
    SearchBox,
    ToggleRefinement,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import {
    ClearFiltersMobile,
    PriceSlider,
    NoResults,
    ResultsNumberMobile,
    SaveFiltersMobile,
} from '../../components/algolia/widgets';

import './Theme.css';
import './Catalog.css';

import Hit from '../../components/algolia/Hit';

import searchIcon from '../../assets/search-glass.png';

const searchClient = algoliasearch('1JOTMRDRXX', '37a40455b6581a42cc48578ddf213f07');
const index = searchClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX);

const Catalog = (props) => {
    const containerRef = useRef(null);
    const headerRef = useRef(null);

    let hitsC = [];

    const backup = () => {
        index.search('', {
            hitsPerPage: 99999999
        }).then(({ hits }) => {
            hits.forEach(hit => {
                hitsC.push({
                    'up_group': hit.up_group,
                    'upgrade_description': hit.upgrade_description,
                    'price': hit.price,
                    'room': hit.room,
                    'level': hit.level,
                    'structural': hit.structural,
                    'centre': hit.centre,
                    'phase': hit.phase,
                    'product_description': hit.product_description,
                    'color': hit.color,
                    'size': hit.size,
                    'option': hit.option,
                    'image': hit.image,
                });
            });

            console.log(JSON.stringify(hitsC));
        });
    };

    const openFilters = () => {
        document.body.classList.add('filtering');
        window.scrollTo(0, 0);
        window.addEventListener('keyup', onKeyUp);
        window.addEventListener('click', onClick);
    };

    const closeFilters = () => {
        document.body.classList.remove('filtering');
        containerRef.current.scrollIntoView();
        window.removeEventListener('keyup', onKeyUp);
        window.removeEventListener('click', onClick);
    }

    const onKeyUp = (event) => {
        if (event.key !== 'Escape') {
            return;
        }

        closeFilters();
    }

    const onClick = (event) => {
        if (event.target !== headerRef.current) {
            return;
        }

        closeFilters();
    }

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={process.env.REACT_APP_ALGOLIA_INDEX}
            searchState={props.searchState}
            createURL={props.createURL}
            onSearchStateChange={props.onSearchStateChange}
        >
            <header className='header header-animated' ref={headerRef}>
                <p className='header-logo'>
                    {/* <AlgoliaSvg /> */}
                </p>

                <SearchBox
                    translations={{
                        submitTitle: 'Search',
                        resetTitle: 'Clear your search query',
                        placeholder: 'Product, brand, color, …',
                    }}
                    submit={<img src={searchIcon} alt='Search' />}
                />
            </header>

            <Configure
                attributesToSnippet={['product_description:10']}
                snippetEllipsisText='…'
                removeWordsIfNoResults='allOptional'
            />

            <main className='container' ref={containerRef}>
                {/* <button style={{ width:50, height:20 }} onClick={backup}>BCK</button> */}
                <div className='container-wrapper'>
                    <section className='container-filters' onKeyUp={onKeyUp}>
                        <div className='container-header'>
                            <h2>Filters</h2>

                            <div className='clear-filters' data-layout='desktop'>
                                <ClearRefinements
                                    // translations={{
                                    //     reset: (
                                    //         <>
                                    //             <svg
                                    //                 xmlns='http://www.w3.org/2000/svg'
                                    //                 width='11'
                                    //                 height='11'
                                    //                 viewBox='0 0 11 11'
                                    //             >
                                    //                 <g fill='none' fillRule='evenodd' opacity='.4'>
                                    //                     <path d='M0 0h11v11H0z' />
                                    //                     <path
                                    //                         fill='#000'
                                    //                         fillRule='nonzero'
                                    //                         d='M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z'
                                    //                     />
                                    //                 </g>
                                    //             </svg>
                                    //             Clear filters
                                    //         </>
                                    //     ),
                                    // }}
                                />
                            </div>

                            <div className='clear-filters' data-layout='mobile'>
                                <ResultsNumberMobile />
                            </div>
                        </div>

                        <div className='container-body'>
                            {/* <Panel header='Category'>
                                <HierarchicalMenu
                                    attributes={[
                                        'hierarchicalCategories.lvl0',
                                        'hierarchicalCategories.lvl1',
                                    ]}
                                />
                            </Panel> */}

                            <Panel header='Projects'>
                                <RefinementList
                                    attribute='centre'
                                    searchable={true}
                                    facetOrdering={true}
                                    translations={{
                                        placeholder: 'Search for projects...',
                                    }}
                                />
                            </Panel>

                            <Panel header='Groups'>
                                <RefinementList
                                    attribute='up_group'
                                    searchable={true}
                                    facetOrdering={true}
                                    translations={{
                                        placeholder: 'Search for groups...',
                                    }}
                                />
                            </Panel>

                            <Panel header='Rooms'>
                                <RefinementList
                                    attribute='room'
                                    searchable={true}
                                    facetOrdering={true}
                                    translations={{
                                        placeholder: 'Search for rooms…',
                                    }}
                                />
                            </Panel>

                            <Panel header='Levels'>
                                <RefinementList
                                    attribute='level'
                                    searchable={false}
                                    facetOrdering={true}
                                    translations={{
                                        placeholder: 'Search for brands…',
                                    }}
                                />
                            </Panel>

                            <Panel header='Price'>
                                <PriceSlider attribute='price' />
                            </Panel>

                            <Panel header='Structural items'>
                                <ToggleRefinement
                                    attribute='structural'
                                    label='Display only'
                                    value={true}
                                />
                            </Panel>

                            <Panel header='Color'>
                                <RefinementList
                                    attribute='color'
                                    searchable={false}
                                    facetOrdering={true}
                                    translations={{
                                        placeholder: 'Search for colors…',
                                    }}
                                />
                            </Panel>

                            <Panel header='Size'>
                                <RefinementList
                                    attribute='size'
                                    searchable={false}
                                    facetOrdering={true}
                                    translations={{
                                        placeholder: 'Search for size…',
                                    }}
                                />
                            </Panel>

                            <Panel header='Options'>
                                <RefinementList
                                    attribute='option'
                                    searchable={false}
                                    facetOrdering={true}
                                    translations={{
                                        placeholder: 'Search for options…',
                                    }}
                                />
                            </Panel>
                        </div>
                    </section>

                    <footer className='container-filters-footer' data-layout='mobile'>
                        <div className='container-filters-footer-button-wrapper'>
                            <ClearFiltersMobile containerRef={containerRef} />
                        </div>

                        <div className='container-filters-footer-button-wrapper'>
                            <SaveFiltersMobile onClick={closeFilters} />
                        </div>
                    </footer>
                </div>

                <section className='container-results'>
                    <header className='container-header container-options'>
                        {/* <SortBy
                            className='container-option'
                            defaultRefinement='dev_products'
                            items={[
                                {
                                    label: 'Sort by featured',
                                    value: 'dev_products',
                                },
                                {
                                    label: 'Price ascending',
                                    value: 'dev_products_price_asc',
                                },
                                {
                                    label: 'Price descending',
                                    value: 'dev_products_price_desc',
                                },
                            ]}
                        /> */}

                        <HitsPerPage
                            className='container-option'
                            items={[
                                {
                                    label: '16 hits per page',
                                    value: 16,
                                },
                                {
                                    label: '32 hits per page',
                                    value: 32,
                                },
                                {
                                    label: '64 hits per page',
                                    value: 64,
                                },
                            ]}
                            defaultRefinement={16}
                        />
                    </header>

                    <Hits hitComponent={Hit} />
                    <NoResults />

                    <footer className='container-footer'>
                        <Pagination
                            padding={2}
                            showFirst={false}
                            showLast={false}
                            // translations={{
                            //     previous: (
                            //         <svg
                            //             xmlns='http://www.w3.org/2000/svg'
                            //             width='10'
                            //             height='10'
                            //             viewBox='0 0 10 10'
                            //         >
                            //             <g
                            //                 fill='none'
                            //                 fillRule='evenodd'
                            //                 stroke='#000'
                            //                 strokeLinecap='round'
                            //                 strokeLinejoin='round'
                            //                 strokeWidth='1.143'
                            //             >
                            //                 <path d='M9 5H1M5 9L1 5l4-4' />
                            //             </g>
                            //         </svg>
                            //     ),
                            //     next: (
                            //         <svg
                            //             xmlns='http://www.w3.org/2000/svg'
                            //             width='10'
                            //             height='10'
                            //             viewBox='0 0 10 10'
                            //         >
                            //             <g
                            //                 fill='none'
                            //                 fillRule='evenodd'
                            //                 stroke='#000'
                            //                 strokeLinecap='round'
                            //                 strokeLinejoin='round'
                            //                 strokeWidth='1.143'
                            //             >
                            //                 <path d='M1 5h8M5 9l4-4-4-4' />
                            //             </g>
                            //         </svg>
                            //     ),
                            // }}
                        />
                    </footer>
                </section>
            </main>

            <aside data-layout='mobile'>
                <button
                    className='filters-button'
                    data-action='open-overlay'
                    onClick={openFilters}
                >
                    {/* <svg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 16 14'>
                        <path
                            d='M15 1H1l5.6 6.3v4.37L9.4 13V7.3z'
                            stroke='#fff'
                            strokeWidth='1.29'
                            fill='none'
                            fillRule='evenodd'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg> */}
                    Filters
                </button>
            </aside>
        </InstantSearch>
    );
};

export default Catalog;