import React from 'react';
import { Highlight, Snippet } from 'react-instantsearch-dom';
import { formatNumber } from '../../../utils';

const Hit = ({ hit }) => (
    <article className='hit'>
        <header className='hit-image-container'>
            <img src={hit.image} alt={hit.image} className='hit-image' />
        </header>

        <div className='hit-info-container'>
            <p className='hit-category'>{hit.up_group}</p>
            <h1>
                <Highlight attribute='upgrade_description' tagName='mark' hit={hit} />
            </h1>
            <p className='hit-description'>
                <Snippet attribute='product_description' tagName='mark' hit={hit} />
            </p>

            { hit.price !== 0 && hit.price !== null
                ? <footer>
                    <p>
                        <span className='hit-em'>$</span>{' '}
                        <strong>{formatNumber(hit.price)}</strong>
                    </p>
                </footer>
                : ''
            }
        </div>
    </article>
);

export default Hit;