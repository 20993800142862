import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Catalog from '../../containers/Catalog';
import NotFound from '../../containers/NotFound';

export default props => (
    <Routes>
        <Route path='/catalog' element={<Catalog />} />

        <Route path='*' element={<NotFound />} />
    </Routes>
);