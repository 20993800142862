import React from 'react';
import { Box, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    box: {
        textAlign: 'center',
        margin: 100,
    },
}));

const NotFound = () => {
    const classes = useStyles();

    return (
        <Box className={classes.box}>
            <h1>PAGE NOT FOUND</h1>
            <p>
                Sorry, we couldn't find the page you are looking for.
                Maybe you're interested on our <Link href='/catalog'>Catalog</Link>
            </p>
        </Box>
    );
};

export default NotFound;