import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { connectRange } from 'react-instantsearch-dom';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { formatNumber } from '../../../utils';
import './PriceSlider.css';

const useStyles = makeStyles({
    'ais-RangeSlider.slider-rail': {
        backgroundColor: 'rgba(65, 66, 71, 0.08)',
        borderRadius: 3,
        cursor: 'pointer',
        height: 3,
        position: 'absolute',
        width: '100%',
    },
    'ais-RangeSlider.slider-track': {
        backgroundColor: '#2283f1',
        borderRadius: 3,
        cursor: 'pointer',
        height: 3,
        position: 'absolute',
    },
    'ais-RangeSlider.slider-tick': {
        cursor: 'grab',
        display: 'flex',
        fontSize: '0.75rem',
        fontWeight: 'bold',
        position: 'absolute',
        textAlign: 'center',
        top: -28,
        transform: 'translateX(-50%)',
        userSelect: 'none',
    },
    'ais-RangeSlider.slider-handle': {
        backgroundImage: 'linear-gradient(to top, #f5f5fa, #fff)',
        borderRadius: '50%',
        boxShadow: '0 4px 11px 0 rgba(37, 44, 97, 0.15), 0 2px 3px 0 rgba(93, 100, 148, 0.2)',
        cursor: 'grab',
        height: 16,
        outline: 'none',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        width: 16,
        zIndex: 1,
    },
    '@media(max - width: 899px)': {
        'ais-RangeSlider.slider-handle': {
            height: '1.5rem',
            width: '1.5rem',
        },
    },
});

function Handle({ domain: [min, max], handle: { id, value, percent }, disabled, getHandleProps }) {
    const classes = useStyles();

    return (
        <>
            {/* Dummy element to make the tooltip draggable */}
            <div
                style={{
                    position: 'absolute',
                    left: `${percent}%`,
                    width: 40,
                    height: 25,
                    transform: 'translate(-50%, -100%)',
                    cursor: disabled ? 'not-allowed' : 'grab',
                    zIndex: 1,
                }}
                aria-hidden={true}
                {...getHandleProps(id)}
            />
            <div
                role='slider'
                className='slider-handle'
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                style={{
                    left: `${percent}%`,
                    cursor: disabled ? 'not-allowed' : 'grab',
                }}
                {...getHandleProps(id)}
            />
        </>
    );
}

const PriceSlider = ({ min, max, refine, currentRefinement, canRefine }) => {
    const [ ticksValues, setTicksValues ] = useState([
        currentRefinement.min,
        currentRefinement.max,
    ]);

    useEffect(() => {
        setTicksValues([currentRefinement.min, currentRefinement.max]);
    }, [currentRefinement]);

    const onChange = (values) => {
        refine({ min: values[0], max: values[1] });
    };

    if (!canRefine || ticksValues[0] === undefined || ticksValues[1] === undefined) {
        return null;
    }

    return (
        <Slider
            mode={2}
            step={1}
            domain={[min, max]}
            values={[currentRefinement.min, currentRefinement.max]}
            disabled={!canRefine}
            onChange={onChange}
            onUpdate={setTicksValues}
            rootStyle={{ position: 'relative', marginTop: '1.5rem' }}
            className='ais-RangeSlider'
        >
            <Rail>
                {({ getRailProps }) => (
                    <div className='slider-rail' {...getRailProps()} />
                )}
            </Rail>

            <Tracks left={false} right={false}>
                {({ tracks, getTrackProps }) => (
                    <div>
                        {tracks.map(({ id, source, target }) => (
                            <div
                                key={id}
                                className='slider-track'
                                style={{
                                    left: `${source.percent}%`,
                                    width: `${target.percent - source.percent}%`,
                                }}
                                {...getTrackProps()}
                            />
                        ))}
                    </div>
                )}
            </Tracks>

            <Handles>
                {({ handles, getHandleProps }) => (
                    <div>
                        {handles.map((handle) => (
                            <Handle
                                key={handle.id}
                                handle={handle}
                                domain={[min, max]}
                                getHandleProps={getHandleProps}
                            />
                        ))}
                    </div>
                )}
            </Handles>

            <Ticks values={ticksValues}>
                {({ ticks }) => (
                    <div>
                        {ticks.map(({ id, count, value, percent }) => (
                            <div
                                key={id}
                                className='slider-tick'
                                style={{
                                    marginLeft: `${-(100 / count) / 2}%`,
                                    width: `${100 / count}%`,
                                    left: `${percent}%`,
                                }}
                            >
                                <span style={{ color: '#2283f1', marginRight: 4 }}>$</span>
                                {formatNumber(value)}
                            </div>
                        ))}
                    </div>
                )}
            </Ticks>
        </Slider>
    );
};

export default connectRange(PriceSlider);